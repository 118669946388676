import React, { useEffect, useState } from 'react'
import { RefinementList, useCurrentRefinements } from 'react-instantsearch-hooks-web';

import exitIcon from '../assets/x.svg';

const transformItems = (items) => {
    items = items.filter(function (item) {
        return item.count !== 0;
    })
    return items.map((item) => ({
        ...item,
        label: " " + item.label + " ",
        count: `(${item.count})`
    }));
};

function MobileRefinement(props) {
    let containerID = "MobileRefinementID-" + props.attribute;
    return (
        <div className="MobileRefinement" id={containerID}>
            <button onClick={() => props.closeRefinement(containerID)}><img id="MobileRefinement-CloseBtn" src={exitIcon} alt="Exit icon" /></button>
            <h3>{props.name}</h3>
            <RefinementList attribute={props.attribute} limit={6} showMore showMoreLimit={30} transformItems={transformItems} classNames={{
                root: "MobileRefinement-Root",
                showMore: "MobileRefinement-ShowMore",
                labelText: "refinement-Item",
                item: "refinement-Item",
                list: "refinement-Item",
                count: "refinement-Item",
            }} />
        </div>
    )
}

export function MobileRefinements() {
    const { items } = useCurrentRefinements();
    const [refinementOpen, setrefinementOpen] = useState(false);
    const [resourceType, setResourceType] = useState("Resource Type");
    const [affiliation, setAffiliation] = useState("Affiliation");
    const [location, setLocation] = useState("Location");
    const [cost, setCost] = useState("Cost")


    useEffect(() => {
        if (items.length > 0) {
            let currResourceTypes = items.find(obj => obj.attribute === "resource_type")?.refinements;
            let currAffiliations = items.find(obj => obj.attribute === "affiliation")?.refinements;
            let currLocations = items.find(obj => obj.attribute === "locations.location")?.refinements;
            let currCosts = items.find(obj => obj.attribute === "cost.status")?.refinements;

            if (currResourceTypes && currResourceTypes.length > 0) {
                currResourceTypes.length > 1 ? setResourceType(`Resource Type • ${currResourceTypes.length}`) : setResourceType(currResourceTypes[0].value);
            }
            else {
                setResourceType('Resource Type');
            }

            if (currAffiliations && currAffiliations.length > 0) {
                currAffiliations.length > 1 ? setAffiliation(`Affiliation • ${currAffiliations.length}`) : setAffiliation(currAffiliations[0].value);
            }
            else {
                setAffiliation('Affiliation');
            }

            if (currLocations && currLocations.length > 0) {
                currLocations.length > 1 ? setLocation(`Location • ${currLocations.length}`) : setLocation(currLocations[0].value);
            }
            else {
                setLocation('Location');
            }

            if (currCosts && currCosts.length > 0) {
                currCosts.length > 1 ? setCost(`Cost • ${currCosts.length}`) : setCost(currCosts[0].value);
            }
            else {
                setCost('Cost');
            }
        }
        else {
            setResourceType('Resource Type');
            setAffiliation('Affiliation');
            setLocation('Location');
            setCost('Cost');
        }
    }, [resourceType, items])

    function expandRefinement(containerID) {

        if (refinementOpen) {
            document.getElementById(containerID).style.width = "0";
        }
        else {
            document.getElementById(containerID).style.width = "100%";
        }
        setrefinementOpen(!refinementOpen);
    }

    return (
        <div className="Discover-MobileRefinements">

            <button onClick={() => expandRefinement("MobileRefinementID-resource_type")} className={"Refinement-MobileContainer " + (resourceType === "Resource Type" ? 'btn-light' : 'btn-dark')}>
                <h5>{resourceType}</h5>
            </button>
            <MobileRefinement attribute="resource_type" name="Resource Type" closeRefinement={expandRefinement} />

            <button onClick={() => expandRefinement("MobileRefinementID-affiliation")} className={"Refinement-MobileContainer " + (affiliation === "Affiliation" ? 'btn-light' : 'btn-dark')}>
                <h5>{affiliation}</h5>
            </button>
            <MobileRefinement attribute="affiliation" name="Affiliation" closeRefinement={expandRefinement} />

            <button onClick={() => expandRefinement("MobileRefinementID-locations.location")} className={"Refinement-MobileContainer " + (location === "Location" ? 'btn-light' : 'btn-dark')}>
                <h5>{location}</h5>
            </button>
            <MobileRefinement attribute="locations.location" name="Location" closeRefinement={expandRefinement} />

            <button onClick={() => expandRefinement("MobileRefinementID-cost.status")} className={"Refinement-MobileContainer " + (cost === "Cost" ? 'btn-light' : 'btn-dark')}>
                <h5>{cost}</h5>
            </button>
            <MobileRefinement attribute="cost.status" name="Cost" closeRefinement={expandRefinement} />

        </div>
    )
}