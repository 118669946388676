import React from 'react';
import { connectHitInsights } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';
import aa from 'search-insights'

import { ResourceTag } from './resourcetag.component';

function handleClick(hit, sendEvent, searchBased) {
    if(searchBased) {
        sendEvent('click', hit, 'Resource Clicked');
    }
    else {
        aa('init', {
            appId: 'R9QZ2Z9NX6',
            apiKey: '86fea6b30f8da2b519e836125b13c968'
        })
        aa('clickedObjectIDs', {
            userToken: 'test-user',
            index: 'Resource_Dataset',
            eventName: 'Resource Clicked',
            objectIDs: [hit.objectID],
        })
    }
}

function ResourceCard(props) {
    if (props.size === "large") {
        return (
            <Link className="border card-large flex-row" target="_blank" to={"/" + props.resource.objectID} state={{ name: props.resource.name, objectID: props.resource.objectID }} key={props.resource.objectID} onClick={() => {
                handleClick(props.resource, props.sendEvent, true)
            }}>
                {props.resource.parent ? <h5 className="card-parent-large">🏠 &nbsp; {props.resource.parent['name']}</h5> : null}
                <div className="flex-col card-content">
                    <div className="card-col subcontainer-card">
                        <h3 className="resourceName">{props.resource.name}</h3>
                        <div className="tagcontainer-card">
                            <ResourceTag mode="type" size="small" name={props.resource.resource_type} />
                            <ResourceTag mode="affiliation" size="small" name={props.resource.affiliation} />
                            {props.resource.hasOwnProperty("cost") ? <ResourceTag mode="cost" size="small" name={props.resource.cost.status} /> : null}
                        </div>
                        <p className='subcontainer-card'>{props.resource.short_desc}</p>
                    </div>
                    <div className="card-col subcontainer-card">
                        <ul>
                            {props.resource.services 
                            ? props.resource.services.slice(0,3).map(service => {
                                return <><li className="card-service">{service.name}</li><br /></>
                            })
                            : null
                            }
                        </ul>
                        {props.resource.services && props.resource.services.length > 3
                            ? <Link to={"/" + props.resource.objectID + "#services"}><h6 className="ResourceCard-Link">Show more services</h6></Link>
                            : null
                        }
                    </div>
                </div>
            </Link>
        );
    }
    else if (props.size === "mobile") {
        return (
            <Link className="border card-large flex-row" target="_blank" to={"/" + props.resource.objectID} state={{ name: props.resource.name, objectID: props.resource.objectID }} key={props.resource.objectID} onClick={() => {
                handleClick(props.resource, props.sendEvent, true)
            }}>
                {props.resource.parent ? <h5 className="card-parent-large">🏠 &nbsp; {props.resource.parent['name']}</h5> : null}
                <div className="flex-col card-content subcontainer-mobilecard">
                    <div className="subcontainer-mobilecard">
                        <h3 className="resourceName">{props.resource.name}</h3>
                        <div className="tagcontainer-card">
                            <ResourceTag mode="type" size="small" name={props.resource.resource_type} />
                            <ResourceTag mode="affiliation" size="small" name={props.resource.affiliation} />
                            {props.resource.hasOwnProperty("cost") ? <ResourceTag mode="cost" size="small" name={props.resource.cost.status} /> : null}
                        </div>
                        <p className='subcontainer-card'>{props.resource.short_desc}</p>
                    </div>
                </div>
                <div className="subcontainer-mobilecard">
                    <ResourceTag mode="services" />
                    <ul>
                        {props.resource.services
                            ? props.resource.services.slice(0, 3).map(service => {
                                return <><li className="card-service">{service.name}</li><br /></>
                            })
                            : null
                        }
                    </ul>
                    {props.resource.services && props.resource.services.length > 3
                        ? <Link to={"/" + props.resource.objectID + "#services"}><h6 className="ResourceCard-Link">Show more services</h6></Link>
                        : null
                    }
                </div>
            </Link>
        )
    }
    else {
        return (
            <Link className=" border container-card card-small" target="_blank" to={props.redirect + props.resource.objectID} state={{ name: props.resource.name, objectID: props.resource.objectID }} key={props.resource.objectID} onClick={() => {
                handleClick(props.resource, props.sendEvent, false)
            }}>
                {/* {props.resource.parent ? <h5 className="card-parent-small">🏠 &nbsp; {props.resource.parent['name']}</h5> : null} */}
                <h3 className="subcontainer-card resourceName header-underline-text">{props.resource.name}</h3>
                <div className="subcontainer-card tagcontainer-card">
                    <ResourceTag mode="type" size="small" name={props.resource.resource_type} />
                    <ResourceTag mode="affiliation" size="small" name={props.resource.affiliation} />
                    {props.resource.hasOwnProperty("cost") ? <ResourceTag mode="cost" size="small" name={props.resource.cost.status} /> : null}
                </div>
                <p className="subcontainer-card">{props.resource.short_desc}</p>
            </Link>
        );  
    }
}

// const ResourceCard = connectHitInsights(aa)(ResourceCardComponent);
export default ResourceCard;