// This file contains icons that are mapped to specific tags.
// In the future, we should investigate whether we can feasibly store these icons with the facets in Algolia.

export const RESOURCE_TYPE_ICONS = {
    "Mental Health": '🧠',
    "Specialty Service": '🔍',
    "General Clinic": '🏥',
    "Advice": '?',
    "Emergency Care": '🚨',
    "General Services": '🤝',
    "LGBTQ+ Care": '🏳️‍🌈',
    "Lifestyle": '🏃',
    "Sexual Health": '🌀',
    "Women's Health": '🚺',
    "COVID-19 Services": '🦠',
    "Dental Care": '🦷',
    "Drug Services": '💊',
    "Assault Support": '💜',
    "Insurance": '💰',
    "Nutrition": '🍎'
}

export const RESOURCE_AFFILIATION_ICONS = {
    "School-Run": '📚',
    "Off-Campus": '🌎',
    "Student-Run": '👨‍🎓',
    "School Partner": '🤝'
}

export const RESOURCE_COST_ICONS = {
    "free": '🤑',
    "fees apply": '💰',
}

export const DELIVERY_ICONS = {
    "virtual": '💻',
    "in-person": '🧑🏽‍⚕️'
}