import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import algoliasearch from 'algoliasearch';
import { InstantSearch } from 'react-instantsearch-hooks-web';

import phoneSVG from '../assets/phone.svg'
import webSVG from '../assets/web.svg';
import { CircularProgress } from '@mui/material';

import { Service } from '../components/service.component';
import { ResourceTag } from '../components/resourcetag.component';
import { SearchBar } from '../components/searchbar.component';
import { EmergencyBanner } from '../components/emergency.component';
import { searchRouting } from '../utils/routemapping';

import { history } from 'instantsearch.js/es/lib/routers';

const searchClient = algoliasearch('R9QZ2Z9NX6', '86fea6b30f8da2b519e836125b13c968');
const index = searchClient.initIndex('Resource_Dataset');
const indexName = "Resource_Dataset"

export function ResourcePage(props) {
    const [name, setName] = useState(null);
    const [shortDes, setShortDes] = useState(null);
    const [longDes, setLongDes] = useState(null); 
    const [type, setType] = useState(null);
    const [affiliation, setAffiliation] = useState(null);
    const [link, setLink] = useState(null);
    const [phone, setPhone] = useState([]);
    const [services, setServices] = useState([]);
    const [parent, setParent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [costStatus, setCostStatus] = useState(null);
    const [costContent, setCostContent] = useState(null);
    const [currLocation, setCurrLocation] = useState({})
    const [currLocationIdx, setCurrLocationIdx] = useState(0)
    const location = useLocation();
    const [mobile, setMobile] = useState(false);
    // NOTE: Currently, the fetch to Elastic Beanstalk below does not work in our deployed Amplify app.
    //       This is because Amplify will only accept HTTPS connections, and EB only offers HTTP.
    //       Once we have time to configure EB to HTTPS, the code below should replace any API calls to Algolia.
    // function getResourceContent() {
    //     axios.get('http://compass-umich-api-dev.us-east-1.elasticbeanstalk.com/resources/' + location.state.name + '/')
    //         .then(response => {
    //             setName(response.data["name"]);
    //             setShortDes(response.data["short_desc"]);
    //             setType(response.data["resource_type"]);
    //             setAffiliation(response.data["affiliation"]);
    //             setPhone(JSON.parse(response.data["phone"].replaceAll("'", '"')));
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

    // const routing = {
    //     router: history(),
    //     stateMapping: {
    //         stateToRoute(uiState) {
    //             const indexUiState = uiState[indexName];
    //             return {
    //                 q: indexUiState.query,
    //                 page: indexUiState.page,
    //             };
    //         },
    //         routeToState(routeState) {
    //             return {
    //                 [indexName]: {
    //                     query: routeState.q,
    //                     page: routeState.page,
    //                 },
    //             };
    //         },
    //     },
    // };

    // Fires when component is mounted and updated
    useEffect(() => {
        const objectID = location.pathname.substring(1);
        index.getObject(objectID).then(resource => {
            setLocations(resource["locations"]);
            (resource["locations"] === undefined) ? setCurrLocation('') : setCurrLocation(resource["locations"][0]);
            setLoading(false);
            setName(resource["name"]);
            setShortDes(resource["short_desc"]);
            (resource['long_desc'] === undefined ? setLongDes(null) : setLongDes(resource['long_desc']));
            setType(resource["resource_type"]);
            setAffiliation(resource["affiliation"]);
            setParent(resource['parent']);
            setDelivery(resource['delivery']);
            setLink(resource["link"]);
            if ("services" in resource) {
                setServices(resource["services"])
            }
            setPhone(resource["phone"]);


            if ("cost" in resource) {
                let cost = resource['cost']

                setCostStatus(resource['cost']['status']);

                if (resource['cost']['status'] === 'free') {
                    let tempCostContent = (<div className='subcontainer-page'>
                        <h2>Cost</h2>
                        <div className="flex-row">
                            <ResourceTag mode="cost" size="large" name={resource['cost']['status']} />
                        </div>
                        <br />
                        <h5 className='ResourceContent-Body'><b>This resource's services are free for {cost['eligibility'] ? cost['eligibility'] : "currently enrolled U-M students"}</b> Please note that the costs for this resource may change at any time and that you are responsible for any costs. Contact {name} to confirm the cost before seeking care.</h5>
                    </div>);
                    setCostContent(tempCostContent);
                }
                else {
                    let tempCostContent = (<div className='subcontainer-page'>
                        <h2>Cost</h2>
                        <div className="flex-row">
                            <ResourceTag mode="cost" size="large" name={resource['cost']['status']} />
                        </div>
                        <br />
                        <h5 className='ResourceContent-Body'><b>Some services offered by this resource may either require an out-of-pocket payment or bill to your insurance. </b>{resource['cost']['explanation'] ? <h5>{resource['cost']['explanation']}</h5> : null} Please note that the costs for this resource may change at any time and that you are responsible for the costs.</h5>
                    </div>);
                    setCostContent(tempCostContent);
                }
            }
            else {
                setCostContent(null);
            }

            if (window.innerWidth < 1060) {
                setMobile(true)
            }
        });
    }, [location, mobile]);

    function phoneNumbers() {
        let numbers = []
        Object.keys(phone).forEach(key => {
            numbers.push(
                <div className="ResourceContent-LearnMoreItem flex-row" key={phone[key]}>
                    <h3>📞</h3>
                    <h4>{key}&nbsp;&nbsp; <b><a href={"tel:" + phone[key]}>{phone[key]}</a></b></h4>
                </div>
            )
        })
        return numbers
    }

    function website() {
        return (
            <a href={link} className="ResourceContent-LearnMoreItem flex-row">
                <h3>🔗</h3>
                <h4>Website</h4>
            </a>
        );
    }

    function hasDuplicateValues(objects, key) {
        const values = {};
        for (const obj of objects) {
            const value = obj[key];
            if (values[value]) {
                return true;
            }
            values[value] = true;
        }
        return false;
    }

    function location_buttons() {
        let buttons = []

        if (hasDuplicateValues(locations, "location")) {
            locations.forEach((location, idx) => {
                buttons.push(
                    <button className={idx === currLocationIdx ? "btn-dark" : "btn-light"} onClick={() => {
                        setCurrLocation(location)
                        setCurrLocationIdx(idx);
                    }}>{location.location} ({location.name})</button>
                )
            })
        }
        else {
            locations.forEach((location, idx) => {
                buttons.push(
                    <button className={idx === currLocationIdx ? "btn-dark" : "btn-light"} onClick={() => {
                        setCurrLocation(location);
                        setCurrLocationIdx(idx);
                    }}>{location.location}</button>
                )
            }) 
        }

        return buttons;
    }

    if (loading) {
        return <CircularProgress />
    }

    document.title = `Compass Health | ${name}`;

    return (
        <>
            <InstantSearch
                searchClient={searchClient}
                indexName="Resource_Dataset"
                className="gallery"
                routing={searchRouting}
                >
                {mobile ? <div className="SearchBar-MobileContainer"><SearchBar searchbtn={false} top={"SearchBar-mobilepos"} prequery="Test" /></div> : null}
                {parent ? <h5 className="ResourceContent-Parent">🏠 &nbsp; <b>{parent['name']}</b></h5> : null}
                <section className="ResourceContent container-page">
                        {mobile ? null : <SearchBar searchbtn={false} top={"SearchBar-top"} prequery="Test" />}
                    <div className="header-underline-text">
                        <h1>{name}</h1>
                    </div>
                    <div className="tagcontainer-page">
                        <ResourceTag mode="type" size="large" name={type} />
                        <ResourceTag mode="affiliation" size="large" name={affiliation} />
                        {costContent ? <ResourceTag mode="cost" size="large" name={costStatus} /> : null}
                        <ResourceTag mode="link" name={name} link={link} />
                    </div>
                    {type === "Emergency Care" ? <EmergencyBanner /> : null}
                    <div>
                        <div className="subcontainer-page">
                            <b><h5 className="ResourceContent-Body">{longDes ? longDes : shortDes}</h5></b>
                        </div>
                        {services.length > 0 ? 
                            <div className="subcontainer-page">
                                <h2 id="services">Services</h2>
                                <div className="ResourceContent-Services flex-col">
                                    {services.map(service => {
                                        return (
                                            <Service service={service} />
                                        )
                                    })}
                                </div>
                            </div>
                        : null}
                        {delivery ? 
                            <div className="subcontainer-page">
                                <h2>Delivery</h2>
                                <div className="flex-row">
                                    {delivery.map(delivery => {
                                        return <ResourceTag mode="delivery" name={delivery} size="large" />
                                    })}
                                </div>
                            </div>
                        : null}
                        {costContent}
                        {locations ? 
                            <div className="subcontainer-page">
                                <h2>Locations</h2>
                                <div className="flex-col">
                                    <div>
                                        {locations ? location_buttons() : null}
                                    </div>
                                    <div className='subcontainer-page'>
                                        <h5><b>{currLocation.name}</b></h5>
                                        <h5>{currLocation.address}</h5>
                                    </div>
                                </div>
                            </div> 
                        : null}
                        <div className="subcontainer-page">
                            <h2>Learn More</h2>
                            <div className="ResourceContent-LearnMore">
                                {link ? website() : null}
                                {phone ? phoneNumbers() : null}
                            </div>
                        </div>
                    </div>
                </section>
            </InstantSearch>
        </>
    );

}

