import React from 'react';

export function Contact() {
    document.title = "Compass Health | Contact Us";
    return (
        <section className="container-page">
            <div className='subcontainer-page'>
                <h1 className="Contact-HeroText">‼️ Spot an Error?</h1>
                <h5>Sometimes we make mistakes. If you spot resource information that is incorrect, see a typo, or find anything else concerning, get in touch with us at <a href="mailto:team@compasshealth.app">team@compasshealth.app</a></h5>
            </div>
            <div className='subcontainer-page'>
                <h1 className="Contact-HeroText">💡 Have an idea for a new Resource?</h1>
                <h5>We’re working as quickly as we can to get every single healthcare resource at Michigan listed on Compass. If you have an idea for a resource that you would love to see here, get in touch with us at <a href="mailto:team@compasshealth.app">team@compasshealth.app</a> or fill out our <a href="https://jyiiwl52rzk.typeform.com/to/XZhlNd2m">Resource Request Form</a></h5>
            </div>
            <div className='subcontainer-page'>
                <h1 className="Contact-HeroText">🤝  Want to collaborate?</h1>
                <h5>It takes a village to make healthcare more accessible. If you are interested in helping us, we would love to chat with you! Get in touch with the entire team at <a href="mailto:team@compasshealth.app">team@compasshealth.app</a> or email Grant directly at <a href="mailto:veldhuis@umich.edu">veldhuis@umich.edu</a></h5>
            </div>

        </section>
    )
}