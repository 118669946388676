import React from 'react';

import infoSVG from '../assets/info.svg'
import linkSVG from '../assets/link.svg'
import { Tooltip, Typography } from '@mui/material'

export function Service(props) {
    const { service } = props;
    let link = <li>{service.name}</li>;
    let callout = null;

    if (service.link) {
        link = <li><a target="_blank" rel="noreferrer" href={service.link} className="Service-Link"><b>{service.name}</b><img id="linkIcon" src={linkSVG} alt="Link icon" /></a></li>
    }
    if (service.page) {
        link = <li><a target="_blank" rel="noreferrer" href={"/" + service.page} className="Service-Link"><b>{service.name}</b><img id="linkIcon" src={linkSVG} alt="Link icon" /></a></li>
    }

    if (service.callout) {
        callout = callout = <Tooltip title={<Typography color="white">{service.callout}</Typography>}><img src={infoSVG} alt="Info icon"/></Tooltip>
    }

    return (
        <>
            <div className="Service-Text">
                {link}
                {callout}
            </div>
            <br />
        </>
    )
}