import React from 'react';
import { useHits } from 'react-instantsearch-hooks-web';

import ResourceCard from './card.component';

// This component is very similar to CardGallery, except instead of using data passed into props,
// it utilizes the useHits Algolia hook to access hits from an Algolia search.

// See the README.md for more information about the difference between these components.
export function CardGalleryHits(props) {
    const { hits, results, sendEvent } = useHits()

    if (hits.length === 0) {
        return <h3 style={{ margin: 20 }}>No results. Try checking your spelling or using a different search term.</h3>
    }

    return hits.map(hit => {
        return (
            <ResourceCard size={props.mobile ? "mobile" : "large"} resource={hit} key={hit.objectID} sendEvent={sendEvent} />
        );
    });
}