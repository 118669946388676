import React, { useEffect, useState } from 'react'
import { TextField, Alert } from '@mui/material';
import { useLocation, Link } from 'react-router-dom'
import algoliasearch from 'algoliasearch';
// import { RJSFSchema } from "@rjsf/utils";
// import validator from "@rjsf/validator-ajv8";
// import Form from "@rjsf/mui";
// import { UiSchema } from "@rjsf/utils";

import { RESOURCE_TYPES, AFFILIATIONS } from '../data/facets';

const algolia_admin_key = process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY
const searchClient = algoliasearch('R9QZ2Z9NX6', '86fea6b30f8da2b519e836125b13c968');
const editClient = algoliasearch('R9QZ2Z9NX6', algolia_admin_key);

const index = searchClient.initIndex('Resource_Dataset');
const editIndex = editClient.initIndex('Resource_Dataset');

export function EditResource(props) {
    const location = useLocation();
    const original_resource = location.state.resource;
    const original_services = location.state.resource.services;

    const [resource, setResource] = useState({ ...original_resource });
    const [services, setServices] = useState([...original_services]);
    const [pages, setPages] = useState([]);
    const [save, setSave] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [deleteBtn, setDeleteBtn] = useState(Array(original_services.length).fill(false));

    useEffect(() => {
        index.search('', {
            facets: ['*'],
            attributesToRetrieve: ['name'],
            hitsPerPage: 100,
        }).then(({ hits }) => {
            setPages([{ name: null, objectID: null }, ...hits])
        });
    }, [])

    // const uiSchema: UiSchema = {
    //     short_desc: {
    //         "ui:widget": "textarea"
    //     },
    //     long_desc: {
    //         "ui:widget": "textarea"
    //     },
    //     "ui:options": {
    //         addable: true
    //     }
    // };

    // const schema: RJSFSchema = {
    //     type: "object",
    //     properties: {
    //         basic: {
    //             title: "Basic Information",
    //             properties: {
    //                 resource_name: {
    //                     title: "Resource Name",
    //                     type: "string",
    //                     default: resource.name
    //                 },
    //                 resource_type: {
    //                     title: "Resource Type",
    //                     type: "string",
    //                     default: resource.resource_type,
    //                     enum: RESOURCE_TYPES
    //                 },
    //                 affiliation: {
    //                     title: "Affiliation",
    //                     type: "string",
    //                     default: resource.affiliation,
    //                     enum: AFFILIATIONS
    //                 },
    //                 short_desc: {
    //                     title: "Short Description",
    //                     type: "string",
    //                     default: resource.short_desc
    //                 },
    //                 long_desc: {
    //                     title: "Long Description",
    //                     type: "string",
    //                     default: resource.long_desc
    //                 }
    //             }
    //         },
    //         contact: {
    //             title: "Contact Information",
    //             properties: {
    //                 link: {
    //                     title: "Link",
    //                     type: "string",
    //                     default: resource.link
    //                 },
    //             }
    //         },
    //         services: {
    //             title: "Services",
    //             type: "array",
    //             default: resource.services,
    //             items: {
    //                 type: "object",
    //                 properties: {
    //                     name: {
    //                         title: "Service Name",
    //                         type: "string"
    //                     },
    //                     link: {
    //                         title: "Service Link",
    //                         type: "string",
    //                     },
    //                     callout: {
    //                         title: "Service Callout",
    //                         type: "string"
    //                     },
    //                     page: {
    //                         title: "Compass Page",
    //                         type: "string",
    //                         enum: pages
    //                     } 
    //                 }
    //             }
    //         }
    //     }
    // };


    function handleReset(e) {
        e.preventDefault();
        setResource(original_resource);
        setServices(original_services);
    }

    function handleSubmit(e) {
        e.preventDefault();
        let saved_resource = {
            ...resource,
            services: services
        }
        setResource(saved_resource)
        editIndex.partialUpdateObject(saved_resource).then(() =>
            setSave(true)
        ).catch((error) => {
            console.log(error);
            setSaveError(true)
        })
    }


    function formChange(e) {
        console.log(e.target.value)
        let key = String(e.target.name);
        setResource({
            ...resource,
            [key]: e.target.value
        })
    }


    return (
        <>
            {save ? <Alert className="AdminEditResource-SaveAlert" severity="success" variant="filled" onClose={() => { setSave(false) }}>Your resource has been updated!</Alert> : <></>}
            {saveError ? <Alert className="AdminEditResource-SaveAlert" severity="error" variant="filled" onClose={() => { setSaveError(false) }}>There has been an error trying to save your changes. Please try again.</Alert> : <></>}
            <div className="banner-edit">
                <Link to={'/admin/readresource/' + original_resource.objectID}>← Published Resource</Link>
                <h3>CURRENTLY EDITING</h3>
            </div>
            <section className="container-page">
                <div>
                    <h1>{original_resource.name}</h1>
                </div>
                {/* <Form schema={schema} validator={validator} uiSchema={uiSchema} /> */}
                <div className="subcontainer-page">
                    <h2 className="header-underline-text">Basic Information</h2>
                    <TextField
                        style={{ marginBottom: '30px' }}
                        fullWidth
                        id="standard-helperText"
                        label="Resource Name"
                        name="name"
                        value={resource.name ? resource.name : ""}
                        variant="standard"
                        onChange={formChange}
                    />
                    <TextField
                        style={{ marginBottom: '30px' }}
                        id="standard-select-currency-native"
                        select
                        label="Resource Type"
                        name="resource_type"
                        value={resource.resource_type ? resource.resource_type : ""}
                        SelectProps={{
                            native: true,
                        }}
                        variant="standard"
                        onChange={formChange}
                    >
                        {RESOURCE_TYPES.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        style={{ marginBottom: '30px' }}
                        id="standard-select-currency-native"
                        select
                        label="Affiliation"
                        name="affiliation"
                        value={resource.affiliation ? resource.affiliation : ""}
                        SelectProps={{
                            native: true,
                        }}
                        variant="standard"
                        onChange={formChange}
                    >
                        {AFFILIATIONS.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        style={{ marginBottom: '30px' }}
                        fullWidth
                        multiline
                        id="standard-helperText"
                        label="Description"
                        name="short_desc"
                        value={resource.short_desc ? resource.short_desc : ""}
                        variant="standard"
                        onChange={formChange}
                    />
                </div>
                <div className="subcontainer-page">
                    <h2 className="header-underline-text">Contact Information</h2>
                    <TextField
                        style={{ marginBottom: '30px' }}
                        fullWidth
                        id="standard-helperText"
                        label="Link"
                        name="link"
                        value={resource.link ? resource.link : ""}
                        variant="standard"
                        onChange={formChange}
                    />
                </div>
                <div className="subcontainer-page">
                    <h2 className="header-underline-text">Services</h2>
                    {services.map((service, idx) => {
                        return (
                            <div className="subcontainer-page">
                                <div className="flex-row AdminEditResource-ServiceHeader">
                                    <h5>{service.name}</h5>
                                    {deleteBtn[idx] 
                                        ? <div className="flex-row">
                                            <h6>Delete this service?</h6>
                                            <button onClick={(e) => {
                                                let new_services = services.slice()
                                                new_services.splice(idx, 1)
                                                setServices(new_services)
                                                setDeleteBtn(Array(services.length).fill(false))
                                            }}><h6>YES</h6></button>
                                            <button onClick={(e) => {
                                                setDeleteBtn(Array(services.length).fill(false)) 
                                            }}
                                            ><h6>NO</h6></button>
                                          </div>
                                        : <button onClick={(e) => {
                                            let new_btns = Array(services.length).fill(false);
                                            new_btns[idx] = true;
                                            setDeleteBtn(new_btns)
                                        }}><h6>DELETE</h6></button>
                                    }
                                </div>
                                <TextField
                                    fullWidth
                                    required
                                    id="standard-helperText"
                                    label="Service Name"
                                    name="name"
                                    value={service.name ? service.name : ""}
                                    variant="outlined"
                                    margin="normal"
                                    idx={idx}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let new_services = services.slice();
                                        new_services[idx] = {
                                            ...new_services[idx],
                                            [e.target.name]: e.target.value
                                        };
                                        setServices(new_services);
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="standard-helperText"
                                    label="Service Link"
                                    name="link"
                                    value={service.link ? service.link : ""}
                                    variant="outlined"
                                    margin="normal"
                                    helperText="This field contains a link that redirects the student to an external website relating to this service"
                                    idx={idx}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let new_services = services.slice();
                                        new_services[idx] = {
                                            ...new_services[idx],
                                            [e.target.name]: e.target.value
                                        };
                                        setServices(new_services);
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="standard-helperText"
                                    label="Service Callout"
                                    name="callout"
                                    value={service.callout ? service.callout : ""}
                                    variant="outlined"
                                    margin="normal"
                                    helperText="This text will show when students hover over this service."
                                    idx={idx}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let new_services = services.slice();
                                        new_services[idx] = {
                                            ...new_services[idx],
                                            [e.target.name]: e.target.value
                                        };
                                        setServices(new_services);
                                    }}
                                />
                                <TextField
                                    style={{ marginBottom: '30px' }}
                                    id="standard-select-currency-native"
                                    select
                                    label="Compass Page"
                                    name="page"
                                    value={service.page ? service.page : ""}
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    helperText="If this service has a page on Compass that you want to link to, select it here."
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let new_services = services.slice();
                                        new_services[idx] = {
                                            ...new_services[idx],
                                            [e.target.name]: e.target.value
                                        };
                                        setServices(new_services);
                                    }}
                                >
                                    {pages.map((page) => (
                                        <option key={page.objectID} value={page.objectID}>
                                            {page.name}
                                        </option>
                                    ))}
                                </TextField>
                            </div>
                        )
                    })}
                    <button className="btn-light" onClick={(e) => {
                        let new_services = services.slice()
                        let null_obj = {...services[0]}
                        Object.keys(null_obj).forEach(key => null_obj[key] = null)
                        new_services.push(null_obj)
                        setServices(new_services)
                    }}>Add New Service</button>
                </div>
            </section>
            <div className="AdminEditResource-SaveSection flex-row">
                <button onClick={handleReset}>
                    <h5>RESET</h5>
                </button>
                <button onClick={handleSubmit}>
                    <h5>SAVE</h5>
                </button>
            </div>
        </>
    )
}
