import React from 'react';

import ResourceCard from './card.component';
import { ExpandCard } from './expandcard.component';

// This component is very similar to CardGalleryHits, except instead of using the useHits Algolia
// hook, we utilize results that are passed in as props.

// See the README.md for more information about the difference between these components.
export function CardGallery(props) {

    const { hits, redirect } = props
    let expandCard = null;

    let cards = hits.map(hit => {
        return (
            <ResourceCard redirect={redirect} resource={hit} key={hit.objectID} />
        );
    });

    if(props.expandLink) {
        expandCard = <ExpandCard redirect={props.expandLink} message={props.message} />
    }

    cards.push(expandCard)

    return cards;
}