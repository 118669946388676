import React, { useEffect, useState } from 'react'
import algoliasearch from 'algoliasearch';
import { useUser } from "@clerk/clerk-react";

import { CardGallery } from '../components/cardgallery.component';

const searchClient = algoliasearch('R9QZ2Z9NX6', '86fea6b30f8da2b519e836125b13c968');
const index = searchClient.initIndex('Resource_Dataset');

export function AdminDashboard() {
    const [algoliaObjects, setAlgoliaObjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useUser();
    const { publicMetadata } = user;
    const { resources } = publicMetadata;

    useEffect(() => {
        for (let i in resources) {
            index.findObject(hit => hit.name === resources[i]).then(insertObj => setAlgoliaObjects(curr => [...curr, insertObj.object]))
        }
        setLoading(false);
    }, [resources]);

    if (loading) {
        return <h1>LOADING</h1>
    }

    return (
        <section className="container-page">
            <h1>{user.firstName}'s Resources</h1>
            <br />
            <h5>Below is a list of resources that you have been assigned to manage.</h5>
            <h5>Notice that a resource is missing? Give us a ring!</h5>
            <br />
            <div className="flex-row">
                <CardGallery hits={algoliaObjects} redirect='/admin/readresource/' />
            </div>
        </section>
    )
}
