import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom'
import algoliasearch from 'algoliasearch';

import phoneSVG from '../assets/phone.svg'
import webSVG from '../assets/web.svg';
import infoSVG from '../assets/infolight.svg'
import editSVG from '../assets/edit.svg'
import { Tooltip, Typography } from '@mui/material'
import { CircularProgress } from '@mui/material';

import { ResourceTag } from '../components/resourcetag.component';
import { Service } from '../components/service.component';

const searchClient = algoliasearch('R9QZ2Z9NX6', '86fea6b30f8da2b519e836125b13c968');
const index = searchClient.initIndex('Resource_Dataset');

export function ReadResource(props) {
    const [name, setName] = useState(null);
    const [shortDes, setShortDes] = useState(null);
    const [type, setType] = useState(null);
    const [affiliation, setAffiliation] = useState(null);
    const [link, setLink] = useState(null);
    const [phone, setPhone] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [objectID, setObjectID] = useState(null)
    const [resource, setResource] = useState(null)
    const [locations, setLocations] = useState([])
    const [currLocation, setCurrLocation] = useState({})
    const [currLocationIdx, setCurrLocationIdx] = useState(0)
    const location = useLocation();

    // NOTE: Currently, the fetch to Elastic Beanstalk below does not work in our deployed Amplify app.
    //       This is because Amplify will only accept HTTPS connections, and EB only offers HTTP.
    //       Once we have time to configure EB to HTTPS, the code below should replace any API calls to Algolia.
    // function getResourceContent() {
    //     axios.get('http://compass-umich-api-dev.us-east-1.elasticbeanstalk.com/resources/' + location.state.name + '/')
    //         .then(response => {
    //             setName(response.data["name"]);
    //             setShortDes(response.data["short_desc"]);
    //             setType(response.data["resource_type"]);
    //             setAffiliation(response.data["affiliation"]);
    //             setPhone(JSON.parse(response.data["phone"].replaceAll("'", '"')));
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }


    // Fires when component is mounted and updated
    useEffect(() => {
        const objectID = location.pathname.substring(20);
        setObjectID(objectID)
        index.getObject(objectID).then(resource => {
            setLoading(false);
            setLocations(resource["locations"]);
            (resource["locations"] === undefined) ? setCurrLocation('') : setCurrLocation(resource["locations"][0]);
            setResource(resource);
            setName(resource["name"]);
            setShortDes(resource["short_desc"]);
            setType(resource["resource_type"]);
            setAffiliation(resource["affiliation"]);
            setLink(resource["link"]);
            if ("services" in resource) {
                setServices(resource["services"])
            }
            setPhone(resource["phone"]);
        });
    }, [location]);

    function phoneNumbers() {
        let numbers = []
        Object.keys(phone).forEach(key => {
            numbers.push(
                <div className="ResourceContent-LearnMoreItem flex-row" key={phone[key]}>
                    <img src={phoneSVG} alt="Phone icon" />
                    <h4>{key}&nbsp;&nbsp; <b>{phone[key]}</b></h4>
                </div>
            )
        })
        return numbers
    }

    function website() {
        return (
            <a href={link} className="ResourceContent-LearnMoreItem flex-row">
                <img src={webSVG} alt="Internet icon" />
                <h4>Website</h4>
            </a>
        );
    }

    function hasDuplicateValues(objects, key) {
        const values = {};
        for (const obj of objects) {
            const value = obj[key];
            if (values[value]) {
                return true;
            }
            values[value] = true;
        }
        return false;
    }

    function location_buttons() {
        let buttons = []

        if (hasDuplicateValues(locations, "location")) {
            locations.forEach((location, idx) => {
                buttons.push(
                    <button className={idx === currLocationIdx ? "btn-dark" : "btn-light"} onClick={() => {
                        setCurrLocation(location)
                        setCurrLocationIdx(idx);
                    }}>{location.location} ({location.name})</button>
                )
            })
        }
        else {
            locations.forEach((location, idx) => {
                buttons.push(
                    <button className={idx === currLocationIdx ? "btn-dark" : "btn-light"} onClick={() => {
                        setCurrLocation(location);
                        setCurrLocationIdx(idx);
                    }}>{location.location}</button>
                )
            })
        }

        return buttons;
    }

    if (loading) {
        return <CircularProgress />
    }

    return (
        <>
            <div className="banner-read">
                <Link to={'/admin'}>← Your Resources</Link>
                <h3>PUBLISHED RESOURCE</h3>
                <Tooltip title={<Typography color="white">This page is what students currently see when searching for this resource.</Typography>}><img id="admin-info" src={infoSVG} alt="Info icon"/></Tooltip>
            </div>
            <section className='container-page'>
                <div className="flex-col">
                    <div className="flex-row header-underline-text AdminReadResource-Header">
                        <h1>{name}</h1>
                        <Link className="flex-row" to={"/admin/editresource/" + objectID} state={{ resource: resource }}>
                            <h5 id="edit-text">EDIT RESOURCE</h5>
                            <img id="edit-icon" src={editSVG} alt="Edit icon" />
                        </Link>
                    </div>
                    <div className="tagcontainer-page">
                        <ResourceTag mode="type" size="large" name={type} />
                        <ResourceTag mode="affiliation" size="large" name={affiliation} />
                    </div>
                    <div>
                        <div className="subcontainer-page">
                            <b><h5>{shortDes}</h5></b>
                        </div>
                        <div className="subcontainer-page">
                            <h2>Services</h2>
                            <div className="ResourceContent-Services flex-col">
                                {services.map(service => {
                                    return (
                                        <Service service={service} />
                                    )
                                })}
                            </div>
                        </div>
                        {locations ?
                            <div className="subcontainer-page">
                                <h2>Locations</h2>
                                <div className="flex-col">
                                    <div>
                                        {locations ? location_buttons() : null}
                                    </div>
                                    <div className='subcontainer-page'>
                                        <h5><b>{currLocation.name}</b></h5>
                                        <h5>{currLocation.address}</h5>
                                    </div>
                                </div>
                            </div>
                            : null}
                        <div className="subcontainer-page">
                            <h2>Learn More</h2>
                            <div className="flex-row">
                                {link ? website() : null}
                                {phone ? phoneNumbers() : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

