import React from 'react';
import { Link } from 'react-router-dom';

import darkLogo from "../assets/Compass_Dark.svg";

export function Footer() {
    return (
        <div className="Footer">
            <div className="Footer-Text">
                <img id="Footer-Logo" src={darkLogo} />
                <p>©2023 Compass Health. All rights reserved. Company confidential.</p>
            </div>
            <div className="Footer-Links">
                <Link className="Footer-Link" to="/">Home</Link>
                <Link className="Footer-Link" to="/search">Search</Link>
                <Link className="Footer-Link" to="/about">About</Link>
                <Link className="Footer-Link" to="/about/team">Team</Link>
                <Link className="Footer-Link" to="/about/contact">Contact</Link>
            </div>
        </div>
    )
}