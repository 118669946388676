import React from 'react';
import veldhuis from '../assets/veldhuisheadshot.png';
import bharadwaj from '../assets/bharadwajheadshot.png';
import reddy from '../assets/reddyheadshot.png';

export function Team() {
    document.title = "Compass Health | Team";
    return (
        <section className="Team container-page">
            <h1 className="About-HeroText primary-color">Who We Are</h1>
            <div className="Team-Headshots">
                <div className="Team-Headshot">
                    <a href="https://www.linkedin.com/in/grant-veldhuis-457205197/" target="_blank">
                        <img src={veldhuis} alt="Headshot of Grant Veldhuis" />
                        <h4><b>Grant Veldhuis</b></h4>
                        <h5>Founder</h5>
                    </a>
                </div>
                <div className="Team-Headshot">
                    <a href="https://www.linkedin.com/in/kishor-bharadwaj/" target="_blank">
                        <img src={bharadwaj} alt="Headshot of Kishor Bharadwaj" />
                        <h4><b>Kishor Bharadwaj</b></h4>
                        <h5>Founder</h5>
                    </a>
                </div>
                <div className="Team-Headshot">
                    <a href="https://www.linkedin.com/in/aparna-reddy-a203/" target="_blank">
                        <img src={reddy} alt="Headshot of Aparna Reddy" />
                        <h4><b>Aparna Reddy</b></h4>
                        <h5>Operations</h5>
                    </a>
                </div>
            </div>
        </section>
    );
}