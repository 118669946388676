import React, { useEffect, useState } from 'react'
import algoliasearch from 'algoliasearch';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { Link, useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import { MENTAL_HEALTH_CAT, BASICS_CAT, LGBTQ_CAT } from '../data/categories'
import { CardGallery } from '../components/cardgallery.component';
import { SearchBar } from '../components/searchbar.component';
import WarningBanner from '../components/banner.component';
import { searchRouting } from '../utils/routemapping';

const searchClient = algoliasearch('R9QZ2Z9NX6', '86fea6b30f8da2b519e836125b13c968');
const index = searchClient.initIndex('Resource_Dataset');

function SearchBanner(props) {
    let typingSequence = [
        'healthcare.',
        2000, // Waits 1s
        'mental health support.',
        2000, // Waits 2s
        'a doctor.',
        2000, // Waits 2s
        'advice.',
        2000, // Waits 2s
        'a wellness coach.',
        2000,
        'healthcare.',
        2000,
        () => {
            console.log('Done typing!'); // Place optional callbacks anywhere in the array
        }
    ]
    // Code below changes the auto-typing animation content when the screen size is exceptionally small to prevent
    // UI issues
    if(window.innerWidth < 400) {
         typingSequence = [
            'healthcare.',
            2000, // Waits 1s
            'mental health help.',
            2000, // Waits 2s
            'a doctor.',
            2000, // Waits 2s
            'advice.',
            2000, // Waits 2s
            'a wellness coach.',
            2000,
            'healthcare.',
            2000,
            () => {
                console.log('Done typing!'); // Place optional callbacks anywhere in the array
            }
        ]
    }

    return (
        <div className="Discover-SearchBanner flex-col">
            <div className="flex-row">
                <h1>Find &thinsp;</h1>
                <TypeAnimation
                    sequence={typingSequence}
                    wrapper="h1"
                    cursor={false}
                    repeat={Infinity}
                    style={{ color: "#E6AF2E" }}
                />
            </div>
            <div className="Discover-DesktopTagline">
                <h1>At the <b>University of Michigan.</b></h1>
            </div>
            <div className="Discover-MobileTagline">
                <h1>All at the</h1>
                <h1><b>University of Michigan.</b></h1>
            </div>
            <InstantSearch
                searchClient={searchClient}
                indexName="Resource_Dataset"
                className="gallery"
            >
                <section className="flex-row Discover-SearchContainer">
                    <SearchBar searchbtn={true} />
                </section>
            </InstantSearch>
        </div>
    )
}

function SearchAdBanner(props) {
    const navigate = useNavigate()
    return (
        <div className="Discover-SearchAdBanner flex-col">
            <h1>Take control of your health.</h1>
            <h2>Find more resources on our Search page.</h2>
            <button className="btn-light" style={{ border: "none" }} onClick={e => navigate("/search/")}>
                SEARCH
            </button>
        </div>
    )
}

export function Discover(props) {
    const [basicResources, setBasicResources] = useState([]);
    const [mentalHealthResources, setMentalHealthResources] = useState([]);
    const [lgbtqResources, setLGBTQResources] = useState([]);

    useEffect(() => {
        for (let i in BASICS_CAT) {
            let objectID = Object.values(BASICS_CAT[i])[0]
            index.getObject(objectID).then(resource => {
                setBasicResources(curr => [...curr, resource])
            })
        }
        for (let i in MENTAL_HEALTH_CAT) {
            let objectID = Object.values(MENTAL_HEALTH_CAT[i])[0]
            index.getObject(objectID).then(resource => {
                setMentalHealthResources(curr => [...curr, resource])
            })
        }
        for (let i in LGBTQ_CAT) {
            let objectID = Object.values(LGBTQ_CAT[i])[0]
            index.getObject(objectID).then(resource => {
                setLGBTQResources(curr => [...curr, resource])
            })
        }
    }, [])

    document.title = "Compass Health";

    return (
        <>
            <SearchBanner />
            <section className="container-page">
                <h1 className="header-underline-primary">Popular Resource Types</h1>
                <div className="Discover-CategoryCards Discover-TypeCards flex-row">
                    <Link className="border card-category" target="_blank" to={"/search?resource_types=General Clinic"}>
                        <div>
                            <h1>🏥</h1>
                        </div>
                        <h5>General Clinic</h5>
                    </Link>
                    <Link className="border card-category" target="_blank" to={"/search?resource_types=Mental Health"}>
                        <div>
                            <h1>🧠</h1>
                        </div>
                        <h5>Mental Health</h5>
                    </Link>
                    <Link className="border card-category" target="_blank" to={"/search?resource_types=Advice"}>
                        <div>
                            <h1>?</h1>
                        </div>
                        <h5>Advice</h5>
                    </Link>
                    <Link className="border card-category" target="_blank" to={"/search?resource_types=Lifestyle"}>
                        <div>
                            <h1>🏃</h1>
                        </div>
                        <h5>Lifestyle</h5>
                    </Link>
                    <Link className="border card-category" target="_blank" to={"/search?resource_types=Emergency Care"}>
                        <div>
                            <h1>🚨</h1>
                        </div>
                        <h5>Emergency Care</h5>
                    </Link>
                </div>
            </section>
            <section className="Discover-Categories container-page">
                <h1 className="header-underline-primary">The Basics</h1>
                <div className="Discover-CategoryCards flex-row">
                    <CardGallery hits={basicResources} redirect="/"/>
                </div>
                <br />
                <h1 className="header-underline-primary">Mental Health</h1>
                <div className="Discover-CategoryCards flex-row">
                    <CardGallery hits={mentalHealthResources} redirect="/" expandLink="/search?resource_types=Mental Health" message="See more Mental Health resources..."/>
                </div>
                <Link to={"/search?resource_types=Mental Health"} state={{ name: "Mental Health", resource_type: "Mental Health", desc: "Classes comes at you fast. That's why we've put together a list of resources that can help get you back on your feet if you are feeling stressed, anxious, or everything all at once. Whether you're looking for in-person therapy sessions or something more convenient, like online options, we've got you covered. So take a deep breath, relax, and make a gameplan with the resources below." }}>
                    <h5>See more Mental Health resources</h5>
                </Link>
                <br />
            </section>
            <SearchAdBanner />
            <section className="Discover-Categories container-page">
                <br />
                <h1 className="header-underline-primary">LGBTQ+ Focused Support</h1>
                <div className="Discover-CategoryCards flex-row">
                    <CardGallery hits={lgbtqResources} redirect="/" expandLink="/search?resource_types=LGBTQ+ Care" message="See more LGBTQ+ Specific resources..." />
                </div>
                <Link to={"/search?resource_types=LGBTQ+ Care"}>
                    <h5>See more LGBTQ+ focused resources</h5>
                </Link>
                <br />
            </section>
        </>
    );
}