import React from 'react'
import { useSearchBox, useClearRefinements } from 'react-instantsearch-hooks-web';
import { useNavigate } from 'react-router-dom';

import searchSVG from '../assets/search.svg'

// This search bar receives the user query and automatially reidrects to the Search page, which will already show query results.
export function SearchBar(props) {
    const { query, refine } = useSearchBox(props);
    const refinementsHook = useClearRefinements(props);
    const { searchbtn, top } = props;
    const navigate = useNavigate()

    function searchRedirect(e) {
        console.log("CLICKED");
        e.preventDefault();
        navigate("/search/?query=" + e.target[0].value);
    }

    return (
        <section className={"flex-col " + top}>
            <form className="SearchBar" onSubmit={searchRedirect}>
                <div className="flex-row">
                    <img src={searchSVG} alt="Magnifying glass icon" />
                    <input className="search-input" placeholder='Resources, support, advice...' onChange={e => {
                            refinementsHook.refine();
                            refine(e.target.value);
                        }
                    } defaultValue={query} />
                    {searchbtn ? <button className="btn-dark SearchBar-InsideBtn" onSubmit={searchRedirect}>SEARCH</button> : null}
                </div>
                {searchbtn ? <button className="btn-dark SearchBar-OutsideBtn" onSubmit={searchRedirect}>SEARCH</button> : null}
            </form>
        </section>
    )
}