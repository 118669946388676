import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import aa from 'search-insights'
// import ReactGA from 'react-ga';
// import "bootstrap/dist/css/bootstrap.min.css";

import Navbar from './components/navbar.component';
import { Search } from './pages/search.page';
import { Discover } from './pages/discover.page';
import Help from './pages/help.page';
import { ResourcePage } from './pages/resourcepage.page'
import { Admin } from './pages/admin.page'
// eslint-disable-next-line
import { Resize } from './pages/resize.page'
import { Category } from './components/category.component';
import { ClerkProvider } from "@clerk/clerk-react";
import { About } from './pages/about.page';
import { Team } from './pages/team.page';
import { Footer } from './components/footer.component';
import { Contact } from './pages/contact.page';

const frontendApi = process.env.REACT_APP_CLERK_FRONTEND_API_KEY;


function ClerkWithRoutes() {
  const navigate = useNavigate();
  return (
    <ClerkProvider
      frontendApi={frontendApi}
      navigate={(to) => navigate(to)}
    >
      <div>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Discover />} />
          <Route exact path="/search" element={<Search />} />
          <Route path="/:uuid" element={<ResourcePage />} />
          <Route path="/category/:category" element={<Category />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/help" element={<Help />} />
          <Route path="/about" element={<About title="Compass Health | About"/>}/>
          <Route path="/about/team" element={<Team title="Compass Health | Team" />}/>
          <Route path="/about/contact" element={<Contact title="Compass Health | Contact" />} />
        </Routes>
        <Footer />
      </div>
    </ClerkProvider>
  );
}

function App() {
  // eslint-disable-next-line
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Router>
      <ClerkWithRoutes />
    </Router>
  );
}

export default App;
