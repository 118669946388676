import React, { Component } from 'react';

export default class Help extends Component {
    render() {
        return (
            <section className='container-page'>
                This is the Help component!
            </section>
        )
    }
}