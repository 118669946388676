import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { UserButton, SignedIn } from "@clerk/clerk-react";

import MobileNavbar from './mobilenav.component';

import lightLogo from "../assets/Compass_Light.svg";
import darkLogo from "../assets/Compass_Dark.svg";

export default function Navbar(props) {
  const location = useLocation()
  const [compassLogo, setCompassLogo] = useState(lightLogo)
  const [textColor, setTextColor] = useState('#FFFFFF')
  const [bgColor, setBgColor] = useState('#7E41FF')
  const [mobile, setMobile] = useState(false)
  const [tryCompass, setTryCompass] = useState(false)
  const [navLinks, setNavLinks] = useState([{ name: "About", link: '/about' }, { name: "Search", link: '/search' }])

  
  
  useEffect(() => {
    // Variables below represent the links that should appear in the NavBar, which
    // changes depending on the page that the user is on.
    const discoverPageLinks = [{name: "About", link: '/about'}, {name: "Search", link: '/search'}]
    const searchPageLinks = [{ name: "About", link: '/about' }]
    const aboutPageLinks = [{ name: "About", link: '/about' }, { name: "Team", link: '/about/team' }, { name: "Contact", link: '/about/contact' }]

    if (location.pathname.slice(1) === "discover" || location.pathname === "/" || location.pathname.slice(1) === "about") {
      setCompassLogo(darkLogo)
      setTextColor('#7E41FF')
      setBgColor('#FFFFFF')
    }
    else {
      setCompassLogo(lightLogo);
      setTextColor('#FFFFFF');
      setBgColor('#7E41FF');
    }

    if (window.innerWidth < 1117) {
      setMobile(true)
    }

    let pathname = location.pathname.split('/')

    if (pathname.includes('about')) {
      setNavLinks(aboutPageLinks);
      setTryCompass(true);
    }
    else if (pathname.includes('search')) {
      setNavLinks(searchPageLinks);
      setTryCompass(false);
    }
    else {
      setNavLinks(discoverPageLinks);
      setTryCompass(false);
    }

  }, [location, mobile])

  return (
    <div className="NavBar" style={{ backgroundColor: bgColor }}>
      <Link to="/"><img className="CompassLogo" alt='Compass logo' src={compassLogo}></img></Link>
      <div className="NavBar-Links">
        {
          mobile ? <MobileNavbar className="MobileNavbar" bgColor={bgColor}/> : (
            <ul className="DesktopNavbar navbar-nav flex-row">
              {
                navLinks.map((obj, index) => (
                  <li key={index}><Link to={obj.link} className="navLink" style={{ color: textColor }}>{obj.name}</Link></li>
                ))
              }
              {tryCompass ? <Link className='btn-light' id="NavBar-TryCompassBtn" to="/">TRY COMPASS</Link> : null}
              {/* <SignedIn>
                <li>
                  <Link to="/admin" className="navLink" style={{ color: textColor }}>Admin</Link>
                </li>
              </SignedIn> */}
            </ul>
          )
        }
      </div>
      {/* <UserButton
        userProfileMode="navigation" /> */}
    </div>
  )
}