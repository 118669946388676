// These are hardcoded categories offering the first few resources that should be displayed on the "/" homepage.
// When the user asks to expand a category, the Algolia API will be called to return everything under a specific 'resource_type'
export const MENTAL_HEALTH_CAT = [
    {"Counseling & Psychological Services": "78e772fd-a3d9-4063-b017-78c125b1604c"},
    {"Wolverine Support Network": "cdf9caf2-8e13-40fe-8cd0-3d537ba73313"},
    {"Crisis Text Line": "c670bc99-056d-4f5e-897b-205f7d6bef5d"},
    {"Wolverine Wellness": "3835cee8-dd98-42f9-9cc9-381108975735"}
]

export const BASICS_CAT = [
    {"University Health Service": "243eaf47-f504-40a1-86d3-302769f2651c"},
    {"Counseling & Psychological Services": "78e772fd-a3d9-4063-b017-78c125b1604c"},
    {"Wolverine Wellness": "3835cee8-dd98-42f9-9cc9-381108975735"},
    {"Nurse Advice by Phone":"e76cf0fe-4ac3-48fb-83c1-da6feb308950"}
]

export const LGBTQ_CAT = [
    {"Gender Affirming Services For Trans/Nonbinary Students": "f49bc963-5bcd-4535-aeb6-08601ce7c5ef"},
    {"Spectrum Center": "ef8b32de-50a5-4781-9e40-95f1248019aa"},
    {"UHS Gender Affirming Care": "9e6c9d99-9219-4e59-9e87-c34ea9042872"}
]