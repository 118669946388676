import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';

import exitIcon from '../assets/x.svg';
import CompassLight from '../assets/Compass_Light.svg';
import hamburgerDark from "../assets/hamburgerDark.svg";
import hamburgerLight from "../assets/hamburgerLight.svg";

const MobileNavbar = (props) => {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(true);
    const [navLinks, setNavLinks] = useState([{ name: "About", link: '/about' }, { name: "Search", link: '/search' }])

    useEffect(() => {
        // Variables below represent the links that should appear in the NavBar, which
        // changes depending on the page that the user is on.
        const discoverPageLinks = [{ name: "About", link: '/about' }, { name: "Search", link: '/search' }]
        const searchPageLinks = [{ name: "About", link: '/about' }]
        const aboutPageLinks = [{ name: "About", link: '/about' }, { name: "Team", link: '/about/team' }, { name: "Contact", link: '/about/contact' }]

        let pathname = location.pathname.split('/')

        if (pathname.includes('about')) {
            setNavLinks(aboutPageLinks);
        }
        else if (pathname.includes('search')) {
            setNavLinks(searchPageLinks);
        }
        else {
            setNavLinks(discoverPageLinks);
        }

    }, [location])

    /* Set the width of the side navigation to 250px */
    function openNav() {
        setMenuOpen(!menuOpen);
        document.getElementById("MobileNav").style.width = "100%";
    }

    /* Set the width of the side navigation to 0 */
    function closeNav() {
        setMenuOpen(!menuOpen);
        document.getElementById("MobileNav").style.width = "0";
    }

    return (
        <div className="MobileNavbar">
            <button onClick={() => openNav()} style={{ color: "white" }}>
                <img src={props.bgColor === '#7E41FF' ? hamburgerLight : hamburgerDark} alt="Menu icon" id="MobileNav-Menu" />
            </button>
            <div id="MobileNav" className="MobileNav">
                <button onClick={() => closeNav()} className="MobileNav-CloseBtn"><img id="MobileNav-ExitBtn" alt="Exit button" src={exitIcon} /></button>
                {
                    navLinks.map((obj, index) => (
                        <Link onClick={() => closeNav()} to={obj.link} className="navLink">{obj.name}</Link>
                    ))
                }
                {/* <Link onClick={() => closeNav()} to="/" className="MobileNav-Link">Home</Link>
                <Link onClick={() => closeNav()} to="/search" className="MobileNav-Link">Search</Link> */}
                <img id="MobileNav-Logo" alt="Compass Logo" src={CompassLight} />
            </div>
        </div>
    );
};

export default MobileNavbar;