import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import algoliasearch from 'algoliasearch';

import { CardGallery } from './cardgallery.component';

const searchClient = algoliasearch('R9QZ2Z9NX6', '86fea6b30f8da2b519e836125b13c968');
const index = searchClient.initIndex('Resource_Dataset');

export function Category(props) {
    const { state } = useLocation();
    const [resources, setResources] = useState([]);

    useEffect(() => {
        index.search('', {
            filters: `resource_type: '${state.resource_type}'`
        }).then(resource => {
            setResources(resource.hits);
        })
    });

    return (
        <div className="container-page flex-col">
            <h1 className="header-underline-text">{state.name}</h1>
            <h5 className="subcontainer-page">{state.desc}</h5>
            <div className="subcontainer-page gallery flex-row">
                <CardGallery hits={resources} redirect={"/"}/>
            </div>
        </div>
    );
}