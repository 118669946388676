import React from 'react';
import compassDesktopPhone from '../assets/Compass-DesktopPhone.jpg';
import maze from '../assets/maze.png';
import stressedstudent from '../assets/stressedstudent.png';
import compassDeviceTablet from '../assets/Compass-DeviceTablet.png';
import stackedcards from '../assets/stackedcards.png';
import stackedsearches from '../assets/stackedsearches.png';
import phoneview from '../assets/phoneview.png';

export function About() {
    document.title = "Compass Health | About";
    return (
        <section className='About flex-col'>
            <div className="About-Subcontainer flex-col subcontainer-page">
                <h1 className="About-HeroText"><b className="primary-color">Finding healthcare at Michigan, reimagined.</b></h1>
                <h1><b className="primary-color">For students, by students.</b></h1>
                <br />
                <img id="About-DesktopPhone" src={compassDesktopPhone} alt="Compass screen shown within a computer screen and phone screen" />
            </div>
            <div className="About-Subcontainer About-ProblemStatement subcontainer-page">
                <img id="About-Maze" src={maze} alt="Maze of roads"/>
                <div className="flex-col">
                    <h3>It’s no secret: The University of Michigan is home to a robust student healthcare ecosystem and world-class hospitals.</h3>
                    <br />
                    <br />
                    <h3>But despite this, <b className="secondary-color">navigating Michigan’s healthcare resources can be overwhelming and stressful.</b></h3>
                </div>
            </div>
            <div className="About-Subcontainer About-Students flex-row subcontainer-page">
                <div className="About-StudentListText flex-col">
                    <h1 className="About-HeroText">This causes many students to...</h1> 
                    <br />
                    <ul className="About-StudentList">
                        <li>😐 Keep going back to the same resources every time, even if they aren’t the best suited for their needs…</li>
                        <li>🧐 Conduct "medical research" on WebMD and Google</li>
                        <li>🤢 Pop an advil and hope for the best</li>
                    </ul>
                </div>
                <img id="About-StressedStudent" src={stressedstudent} alt="Stressed student" />
            </div>
            <div className="About-Subcontainer About-Transition flex-col subcontainer-page">
                <h1 className="About-HeroText secondary-color">Searching for healthcare should be better than this.</h1>
                <h1><b className="secondary-color">That's why we created Compass.</b></h1>
            </div>
            <div className="About-Subcontainer About-Product flex-col subcontainer-page">
                <div className="About-ProductText flex-col">
                    <h1 className="About-HeroText"><b className="About-bold">Compass</b> is the <b className="About-bold">first online platform</b> that helps Michigan students find on-campus care.</h1>
                    <h1 className="About-HeroText">Whether it’s for your mental health, physical illness, or whatever else life throws your way, <br /><b className="About-bold">Compass helps you navigate it.</b></h1>
                </div>
                <br />
                <img id="About-Tablet" src={compassDeviceTablet} alt="Tablet view of Compass homepage" />
                <div className="About-ProductText flex-col">
                    <h1 className="About-HeroText">No more doom scrolling your symptoms on Tiktok. <br /> Compass <b className="About-bold">simplifies</b> your healthcare search.</h1>
                    <h4>With every last pixel designed with the student in mind, finding healthcare at Michigan will never be the same.</h4>
                </div>
                <div className="About-ProductTiles">
                    <div className="About-ProductTile-Place">
                        <img id="About-StackedCards" src={stackedcards} alt="Stacked view of resource cards" />
                        <h3 className="secondary-color">Countless student-focused health resources, all in one place.</h3>
                    </div>
                    <div className="About-ProductTile-Curated">
                        <img id="About-PhoneView" src={phoneview} alt="Phone view of the Wellness Zones resource profile" />
                        <h3 className="secondary-color">Curated with the most important information to help you make your best decision.</h3>
                    </div>
                    <div className="About-ProductTile-Search">
                        <img id="About-StackedSearches" src={stackedsearches} alt="Stacked view of example searches" />
                        <h3 className="secondary-color">Discover resources with fast search and robust filtering.</h3>
                    </div>
                </div>
            </div>
            <div className="About-Action flex-col subcontainer-page">
                <h1 className="About-HeroText"><b className="About-bold secondary-color">Start simplifying your healthcare search <br/> with Compass today.</b></h1>
                <button className="btn-light"><a href="/">Try Compass Now</a></button>
                <h3>Crafted with love. For students, by students.</h3>
            </div>
        </section>
    );
}