import React, { useState, useEffect, useLayoutEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, RefinementList, useInstantSearch } from 'react-instantsearch-hooks-web';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import aa from 'search-insights'

import { CardGalleryHits } from '../components/cardgalleryhits.component';
import { MobileRefinements } from '../components/mobilerefinement.component';
// eslint-disable-next-line
import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../components/searchbar.component';
import { searchRouting } from '../utils/routemapping';


function InsightsMiddleware() {
    const { use } = useInstantSearch();
    
    useLayoutEffect(() => {
        const middleware = createInsightsMiddleware({
            insightsClient: aa,
        });
        
        return use(middleware);
    }, [use]);
    
    return null;
}

aa('setUserToken', 'test-user');

const searchClient = algoliasearch('R9QZ2Z9NX6', '86fea6b30f8da2b519e836125b13c968');
const indexName = "Resource_Dataset"


const transformItems = (items) => {
    items = items.filter(function (item) {
        return item.count !== 0;
    })
    return items.map((item) => ({
        ...item,
        label: " " + item.label.charAt(0).toUpperCase() + item.label.slice(1) + " ",
        count: `(${item.count})`
    }));
};

function DesktopRefinements() {
    return (
        <div className="Discover-Refinements">
            <div className="refinement-Root Refinement-Container">
                <h4>Resource Type</h4>
                <RefinementList attribute="resource_type" limit={3} showMore showMoreLimit={30} transformItems={transformItems} classNames={{
                    root: "refinement-Root",
                    showMore: "refinement-ShowMore",
                    labelText: "refinement-Item",
                    item: "refinement-Item",
                    list: "refinement-Item",
                    count: "refinement-Item",
                }} />
            </div>
            <div className="refinement-Root Refinement-Container">
                <h4>Affiliation</h4>
                <RefinementList attribute="affiliation" limit={5} transformItems={transformItems} classNames={{
                    root: "refinement-Root",
                    showMore: "refinement-ShowMore",
                    labelText: "refinement-Item",
                    item: "refinement-Item",
                    list: "refinement-List",
                    count: "refinement-Item",
                }} />
            </div>
            <div className="refinement-Root Refinement-Container">
                <h4>Location</h4>
                <RefinementList attribute="locations.location" transformItems={transformItems} classNames={{
                    root: "refinement-Root",
                    showMore: "refinement-ShowMore",
                    labelText: "refinement-Item",
                    item: "refinement-Item",
                    list: "refinement-List",
                    count: "refinement-Item",
                }} />
            </div>
            <div className="refinement-Root Refinement-Container">
                <h4>Cost</h4>
                <RefinementList attribute="cost.status" transformItems={transformItems} classNames={{
                    root: "refinement-Root",
                    showMore: "refinement-ShowMore",
                    labelText: "refinement-Item",
                    item: "refinement-Item",
                    list: "refinement-List",
                    count: "refinement-Item",
                }} />
            </div>
        </div>
    );
}


export function Search(props) {
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 1060) {
            setMobile(true)
        }
    }, [mobile])

    document.title = "Compass Health | Search";


    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={indexName}
            className="gallery"
            routing={searchRouting}
        >
            {mobile ? <div className="SearchBar-MobileContainer"><SearchBar searchbtn={false} top={"SearchBar-mobilepos"} prequery="Test" /></div> : null}
            {mobile ? <MobileRefinements /> : null}
            <section className="container-page">
                    {mobile ? null : <SearchBar searchbtn={false} top={"SearchBar-top"} prequery="Test" />}
                    <div className={mobile ? "flex-col" : "flex-row"}>
                        {mobile ? null : <DesktopRefinements />}
                        <div className="gallery-hits flex-col">
                            <CardGalleryHits useHits={true} mobile={mobile ? true : false} />
                        </div>
                    </div>
            </section>
            <InsightsMiddleware />
        </InstantSearch>
    );
}