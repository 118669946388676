import React from 'react';

export function EmergencyBanner(props) {
    return (
        <div className="Emergency">
            <h3>⚠️ If you are experiencing a medical emergency, please seek care immediately.</h3>
            <div className="Emergency-Gallery flex-row">
                <div className="Emergency-Card flex-col">
                    <h5>988 Suicide and Crisis Lifeline</h5>
                    <h5>Available 24/7</h5>
                    <h1> 📞 988 </h1>
                    <a href="https://988lifeline.org"><h5>🌐 &nbsp; Website</h5></a>
                </div>
                <div className="Emergency-Card flex-col">
                    <h5>911 Emergency Services</h5>
                    <h5>Available 24/7</h5>
                    <h1> 📞 911 </h1>
                    <a href="https://www.911.gov"><h5>🌐 &nbsp; Website</h5></a>
                </div>
            </div>
        </div>
    )
}