import React from 'react';

import { RESOURCE_TYPE_ICONS, RESOURCE_AFFILIATION_ICONS, RESOURCE_COST_ICONS, DELIVERY_ICONS } from '../data/icons';

// This is for tags that involve a resource "type", such as "General Clinic" or "Sexual Health"
const ResourceAffiliationTag = props => (
    props.size === "small" ?
        <h6 className="affiliation-tag small-tag" style={{ color: `${props.color}`, borderColor: `${props.color}` }} >{RESOURCE_AFFILIATION_ICONS[props.resource_affiliaton]} &nbsp; {props.resource_affiliaton}</h6> :
        <h5 className="affiliation-tag large-tag" style={{ color: `${props.color}`, borderColor: `${props.color}` }}>{RESOURCE_AFFILIATION_ICONS[props.resource_affiliaton]} &nbsp; {props.resource_affiliaton}</h5>
)

// This is for tags that involve a resource "affiliation". The only two options right now are "School-Run" and "Student-Run"
const ResourceTypeTag = props => (
    props.size === "small" ?
        <h6 className="type-tag small-tag">{RESOURCE_TYPE_ICONS[props.resource_type]} &nbsp; {props.resource_type} &nbsp;</h6> :
        <h5 className="type-tag large-tag">{RESOURCE_TYPE_ICONS[props.resource_type]}  &nbsp; {props.resource_type}</h5>
)

const ResourceWebsiteTag = props => (
    <a className="flex-row" href={props.link}><h5 className="website-tag large-tag">🔗 &nbsp; Website</h5></a>
)

const ResourceServicesTag = props => (
    <h6 className="services-tag">&nbsp; Services</h6>
)

const ResourceCostTag = props => (
    props.size === "small" ?
        <h6 className="affiliation-tag small-tag" style={{ color: `${props.color}`, borderColor: `${props.color}` }} >{RESOURCE_COST_ICONS[props.status]} &nbsp; {props.status}</h6> :
        <h5 className="affiliation-tag large-tag" style={{ color: `${props.color}`, borderColor: `${props.color}` }}>{RESOURCE_COST_ICONS[props.status]} &nbsp; {props.status}</h5>
)

const ResourceDeliveryTag = props => (
    props.size === "small" ?
        <h6 className="affiliation-tag small-tag" style={{ color: `${props.color}`, borderColor: `${props.color}` }} >{DELIVERY_ICONS[props.name]} &nbsp; {props.name}</h6> :
        <h5 className="affiliation-tag large-tag" style={{ color: `${props.color}`, borderColor: `${props.color}` }}>{DELIVERY_ICONS[props.name]} &nbsp; {props.name}</h5>
)

// This component takes in a couple of props:
// "mode": This represents the type of tag we want to render. Choose from "type" or "affiliation"
// "content": This represents what you want the tag to display.
export function ResourceTag(props) {
    const mode = props.mode;
    let tag = null;
    const TAG_COLORS = {
        "Student-Run": "#FF7A00",
        "School-Run": "#183D74",
        "Off-Campus": "#721874",
        "School Partner": "#187474",
        "virtual": "#530071",
        "in-person": "#CD02DF",
        "free": "#039600",
        "fees apply": "#960000"
    }

    if (mode === "type") {
        tag = <ResourceTypeTag resource_type={props.name} size={props.size} />;
    }
    else if (mode === "affiliation") {
        let tag_color = "grey";
        if (props.name in TAG_COLORS) {
            tag_color = TAG_COLORS[`${props.name}`];
        }
        tag = <ResourceAffiliationTag resource_affiliaton={props.name} color={tag_color} size={props.size} />;
    }
    else if (mode === "link") {
        tag = <ResourceWebsiteTag link={props.link} name={props.name} />;
    }
    else if (mode === "services") {
        tag = <ResourceServicesTag />;
    }
    else if (mode === "cost") {
        let tag_color = "grey";
        if (props.name in TAG_COLORS) {
            tag_color = TAG_COLORS[`${props.name}`];
        }
        tag = <ResourceCostTag color={tag_color} size={props.size} status={props.name} />;
    }
    else if (mode === "delivery") {
        let tag_color = "grey";
        if (props.name in TAG_COLORS) {
            tag_color = TAG_COLORS[`${props.name}`];
        }
        tag = <ResourceDeliveryTag color={tag_color} name={props.name} size={props.size} />
    }

    return (
        <>{tag}</>
    )
}