import React from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SignedIn, SignIn, SignUp, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";

import { AdminDashboard } from './admindashboard.page';
import { ReadResource } from './adminreadresource.page';
import { EditResource } from './admineditresource.page';
import { CircularProgress } from '@mui/material';

export function Admin() {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <>
                        <SignedIn>
                            <AdminDashboard />
                        </SignedIn>
                        <SignedOut>
                            <CircularProgress />
                            <RedirectToSignIn />
                        </SignedOut>
                    </>
                }
            />
            <Route
                path="signin"
                element={<SignIn routing="path" path="/sign-in" />}
            />
            <Route
                path="signup"
                element={<SignUp routing="path" path="/sign-up" />}
            />
            <Route 
                path="readresource/:uuid"
                element={<ReadResource />}
            />
            <Route
                path="editresource/:uuid"
                element={<EditResource />}
            />
        </Routes>
    );
}