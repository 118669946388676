import { history } from 'instantsearch.js/es/lib/routers';

const indexName = "Resource_Dataset"

// const encodedCategories = {
//     Cameras: 'Cameras & Camcorders',
//     Cars: 'Car Electronics & GPS',
//     Phones: 'Cell Phones',
//     TV: 'TV & Home Theater',
// };

// const decodedCategories = Object.keys(encodedCategories).reduce((acc, key) => {
//     const newKey = encodedCategories[key];
//     const newValue = key;

//     return {
//         ...acc,
//         [newKey]: newValue,
//     };
// }, {});

function modifyArray(arr) {
    if (arr.length === 1 && arr[0].includes(',')) {
        arr =  arr[0].split(',');
    }

    // Since LGBTQ+ Care has a "+" in it, when this attribute value is included in the URL it is automatically removed when decoded.
    // This is why we need to make sure that it is not in the URL each time.
    arr = arr.map(function(str) {
        if (str === "LGBTQ  Care") {
            return "LGBTQ+ Care";
        } else {
            return str;
        }
    })


    return arr;
}


// Returns a slug from the category name.
// Spaces are replaced by "+" to make
// the URL easier to read and other
// characters are encoded.
// function getCategorySlug(name) {
//     const encodedName = decodedCategories[name] || name;

//     return encodedName.split(' ').map(encodeURIComponent).join('+');
// }

// Returns a name from the category slug.
// The "+" are replaced by spaces and other
// characters are decoded.
// function getCategoryName(slug) {
//     const decodedSlug = encodedCategories[slug] || slug;

//     return decodedSlug.split('+').map(decodeURIComponent).join(' ');
// }

export const searchRouting = {
    router: history({
        windowTitle({ category, query }) {
            const queryTitle = query ? `Results for "${query}"` : 'Search';

            if (category) {
                return `${category} – ${queryTitle}`;
            }

            return queryTitle;
        },

        createURL({ qsModule, routeState, location }) {
            // const urlParts = location.href.match(/^(.*?)\/search/);
            // const baseUrl = `${urlParts ? urlParts[1] : ''}/`;

            // const categoryPath = routeState.category
            //     ? `${getCategorySlug(routeState.category)}/`
            //     : '';
            const queryParameters = {};

            if (routeState.query) {
                queryParameters.query = encodeURIComponent(routeState.query);
            }
            if (routeState.page !== 1) {
                queryParameters.page = routeState.page;
            }
            if (routeState.affiliations) {
                queryParameters.affiliations = routeState.affiliations.map(encodeURIComponent);
            }
            if (routeState.resource_types) {
                queryParameters.resource_types = routeState.resource_types.map(encodeURIComponent);
            }
            if (routeState.locations) {
                queryParameters.locations = routeState.locations.map(encodeURIComponent);
            }

            // const queryString = qsModule.stringify(queryParameters, {
            //     addQueryPrefix: true,
            //     arrayFormat: 'repeat',
            // });

            // return `${baseUrl}search/${categoryPath}${queryString}`;
            return location.href
        },

        parseURL({ qsModule, location }) {
            // const pathnameMatches = location.pathname.match(/search\/(.*?)\/?$/);
            // const category = getCategoryName(
            //     (pathnameMatches?.[1]) || ''
            // );
            const { query = '', page, affiliations = [], resource_types = [], locations = [] } = qsModule.parse(
                location.search.slice(1)
            );

            // `qs` does not return an array when there's a single value.
            const allaffiliations = Array.isArray(affiliations)
                ? affiliations
                : [affiliations].filter(Boolean);
            const finalAffiliations = modifyArray(allaffiliations)

            const allresourcetypes = Array.isArray(resource_types)
                ? resource_types
                : [resource_types].filter(Boolean);
            const finalResourceTypes = modifyArray(allresourcetypes)

            const alllocations = Array.isArray(locations)
                ? locations
                : [locations].filter(Boolean);
            const finalLocations = modifyArray(alllocations)

            return {
                query: decodeURIComponent(query),
                page,
                affiliations: finalAffiliations.map(decodeURIComponent),
                resource_types: finalResourceTypes.map(decodeURIComponent),
                locations: finalLocations.map(decodeURIComponent),
            };
        },
    }),
    stateMapping: {
        stateToRoute(uiState) {
            const indexUiState = uiState[indexName];
            return {
                query: indexUiState.query,
                affiliations: indexUiState.refinementList.affiliation,
                resource_types: indexUiState.refinementList.resource_type,
                locations: indexUiState.refinementList.locations.location
            };
        },
        
        routeToState(routeState) {
            return {
                [indexName]: {
                    query: routeState.query,
                    refinementList: {
                        affiliation: routeState.affiliations,
                        resource_type: routeState.resource_types,
                        "locations.location": routeState.locations
                    },
                },
            }
        }
    }
}